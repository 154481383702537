<template>
  <div>
    <v-card
      flat
      class="datatable-card-background datatable-card-border rounded mb-6"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
          >
            <app-date-picker
              v-model="queryParams.start_date"
              hide-details
              classes="small-input"
              :label="$t('Start Date')"
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
          >
            <app-date-picker
              v-model="queryParams.end_date"
              hide-details
              classes="small-input"
              :label="$t('End Date')"
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="3"
          >
            <v-autocomplete
              v-model="queryParams.customer_id"
              :label="$t('Customer')"
              :items="customersList"
              :item-text="item => item.name"
              :item-value="item => item.id"
              outlined
              dense
              hide-details
              class="small-input"
              :color="$_input_color()"
            />
          </v-col>

          <v-col class="text-end">
            <v-btn
              color="primary"
              depressed
              small
              @click="getData()"
            >
              <v-icon left>
                mdil-magnify
              </v-icon>

              {{ $t('Search') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      flat
      class="datatable-card-background datatable-card-border rounded"
    >
      <v-card-text>
        <v-simple-table dense>
          <template #default>
            <thead>
              <tr>
                <th>{{ $t('Date') }}</th>
                <th>{{ $t('Type') }}</th>
                <th>{{ $t('Invoice No.') }}</th>
                <th>{{ $t('User') }}</th>
                <th>{{ $t('Credit') }}</th>
                <th>{{ $t('Debit') }}</th>
                <th>{{ $t('Balance') }}</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(data, index) in apiData.data"
                :key="index"
              >
                <template v-if="index == 0">
                  <td
                    colspan="4"
                    class="text-center"
                  >
                    {{ $t('Opening balance') }}
                  </td>

                  <td>{{ $_format_number(data.credit) }}</td>
                  <td>{{ $_format_number(data.debit) }}</td>
                  <td>{{ $_format_number(data.balance) }}</td>
                </template>

                <template v-else>
                  <td>{{ $_format_date(data.action_date) }}</td>

                  <td>
                    <v-chip
                      label
                      small
                      outlined
                      :color="data.type == 'Receipt' ? 'green' : 'warning'"
                      :class="data.type == 'Receipt' ? 'white--text' : ''"
                    >
                      {{ data.type }}
                    </v-chip>
                  </td>

                  <td>{{ data.invoice_no }}</td>
                  <td>{{ data.username }}</td>
                  <td>{{ $_format_number(data.credit) }}</td>
                  <td>{{ $_format_number(data.debit) }}</td>
                  <td>{{ $_format_number(data.balance) }}</td>
                </template>
              </tr>
              <tr
                v-if="apiData.totals"
                class="font-weight-bold"
              >
                <td colspan="4" />
                <td>{{ $_format_number(apiData.totals.sum_credit) }}</td>
                <td>{{ $_format_number(apiData.totals.sum_debit) }}</td>
                <td>{{ $_format_number(apiData.totals.balance) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      apiData: {},
      queryParams: {}
    }
  },

  computed: {
    ...mapGetters(['customersList'])
  },

  mounted() {
    this.$_section_title({ title: 'Customer Actions', icon: 'mdil-chart-bar' })
    this.getCustomersList()
  },

  methods: {
    ...mapActions(['getCustomersList']),

    getData() {
      axios.get(`report/customer/${this.queryParams.customer_id}/actions/`, { params: this.queryParams }).then(res => {
        this.apiData = res.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>